<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="card-body mt-2 white">
          <div class="mb-47 text-center">
            <div class="row mb-7 mt-2">
              <!--begin::Label-->
              <label class="col-12 fw-bold mt-5 text">เพิ่มเพื่อนสำเร็จ</label>
              <!--end::Label-->
            </div>
            <!--end::Row-->
          </div>
        </div>

        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="close" class="button">ปิดหน้านี้</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import { defineComponent, onMounted, inject } from "vue";
import liff from "@line/liff";
export default defineComponent({
  name: "Profile",
  components: {
    Header,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");

    onMounted(() => {
      liff.openWindow({
        url: `${process.env.VUE_APP_ADD_FRIEND_MAIN}`,
        external: false,
      });
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const close = () => {
      liff.ready.then(() => {
        liff.closeWindow();
      });
    };

    return {
      close,
    };
  },
});
</script>

<style scoped>
.mn-profile {
  height: 50px;
  min-height: 50px;
  border-bottom: 2px solid var(--color);
  padding: 10px;
  background-color: white;
}

.mini-h {
  font-size: 20px;
  margin-left: 5px;
}

.text {
  font-size: 20px;
  color: #1f9049;
}
</style>
